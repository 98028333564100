import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Link from "../common/Link"

export default function LokalerListItem({ venue, context, lang }) {
  const { title, uri, lokalinfo, featuredImage } = venue
  const { kapacitetLokal, kortBeskrivningLokal, storlekLokal } = lokalinfo
  const img = featuredImage?.node.localFile.childImageSharp
  return (
    <div className="flex flex-col gap-4 ">
      <Link to={uri}>
        <div className="overflow-hidden">
          <GatsbyImage image={getImage(img)} className="hover:brightness-110" />
        </div>
      </Link>
      <div className="flex flex-col gap-2">
        <Link to={uri}>
          <h3 className="mb-0">{title}</h3>
        </Link>

        <>
          {kortBeskrivningLokal && <p>{kortBeskrivningLokal}</p>}
          <div>
            {kapacitetLokal && (
              <p>
                <b> {lang === "en" ? "Capacity:" : "Kapacitet:"}</b>{" "}
                {kapacitetLokal}
              </p>
            )}
            {storlekLokal && (
              <p>
                <b> {lang === "en" ? "Size:" : "Storlek:"}</b> {storlekLokal}
              </p>
            )}
          </div>
        </>
      </div>
    </div>
  )
}
