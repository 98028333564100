import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LokalerListItem from "./LokalerListItem"
export default function LokalerList({ lang, context }) {
  const data = useStaticQuery(graphql`
    {
      sv: allWpPage(filter: { parentDatabaseId: { eq: 7 } }) {
        nodes {
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.5, layout: CONSTRAINED)
                }
              }
            }
          }
          lokalinfo {
            kapacitetLokal
            kortBeskrivningLokal
            langreBeskrivningLokal
            storlekLokal
            mobleringLokal {
              beksrivningMoblering
              sittningstypMoblering {
                bild {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                namnSittningstyp
                kapacitetSittningstyp
              }
            }
            bilderLokal {
              bildBilder {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          title
          uri
        }
      }
      en: allWpPage(filter: { parentDatabaseId: { eq: 796 } }) {
        nodes {
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.5, layout: CONSTRAINED)
                }
              }
            }
          }
          lokalinfo {
            kapacitetLokal
            kortBeskrivningLokal
            langreBeskrivningLokal
            storlekLokal
            mobleringLokal {
              beksrivningMoblering
              sittningstypMoblering {
                bild {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                namnSittningstyp
                kapacitetSittningstyp
              }
            }
            bilderLokal {
              bildBilder {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          title
          uri
        }
      }
    }
  `)

  const venuesSV = data.sv.nodes
  const venuesEN = data.en.nodes

  const venues = lang === "sv" ? venuesSV : lang === "en" ? venuesEN : venuesSV

  return (
    <div>
      <>
        {context === "page" && (
          <div className="grid lg:grid-cols-3 gap-4 lg:gap-8">
            {venues.map((venue, i) => {
              return (
                <LokalerListItem
                  key={i}
                  venue={venue}
                  context={context}
                  lang={lang}
                />
              )
            })}
          </div>
        )}
      </>
      <>
        {context === "index" && (
          <div className="flex flex-col gap-4 lg:gap-8">
            <h2 className="text-center uppercase txt-lg tracking-widest">
              Exempel på våra lokaler
            </h2>
            <div className="grid lg:grid-cols-3 gap-4 lg:gap-8">
              {venues.slice(0, 3).map((venue, i) => {
                return <LokalerListItem key={i} venue={venue} context="index" />
              })}
            </div>
          </div>
        )}
      </>
    </div>
  )
}
