import React from "react"
import PageHeader from "../components/common/PageHeader"
import Layout from "../components/layout"
import LokalerList from "../components/lokaler/LokalerList"

import MeetingEventMenu from "../components/lokaler/MeetingEventMenu"
import SEO from "../components/seo"

export default function LokalerPage() {
  return (
    <Layout lang="sv">
      <SEO title="Lokaler" />
      <div>
        <MeetingEventMenu />
        <div className="max-w-screen-2xl mx-auto px-4 lg:px-8 py-8 lg:py-16">
          <div className="flex flex-col gap-4 lg:gap-8">
            <PageHeader text="Våra lokaler" />
            <LokalerList lang="sv" context="page" />
          </div>
        </div>
      </div>
    </Layout>
  )
}
